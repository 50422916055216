<template>
  <HomePage
    class="main-content"
    :swipe-img-list="swipeImgList"
    :app-code="APP_CODE"
    :api-hosts="API_HOSTS"
    :download-recommend-tag-img="downloadRecommendTagImg"
    :logo-icon="logoIcon"
    ios-tips="下载后图标非大象传媒，开启后即可呈现大象传媒内容"
  />
</template>

<script>
import HomePage from '@/components/common/home-page/index.vue'
import downloadRecommendTagImg from '@/assets/images/hot_tag_recommend_new.png'
import logoIcon from './assets/images/logo_icon.png'

import { APP_CODE, API_HOSTS } from '../config'
import { uploadVisitEvent } from '@/../utils'

export default {
  components: {
    HomePage,
  },
  data() {
    return {
      logoIcon,
      downloadRecommendTagImg,
      swipeImgList: [
        { bg: require('./assets/images/dx_bg_1.png') },
        { bg: require('./assets/images/dx_bg_2.png') },
        { bg: require('./assets/images/dx_bg_3.png') },
        { bg: require('./assets/images/dx_bg_4.png') },
      ],
      APP_CODE,
      API_HOSTS,
    }
  },
  created() {
    uploadVisitEvent(APP_CODE)
  }
}
</script>

<style>
@import url('../../../assets/css/minireset.min.css');
@import url('../../../assets/css/common.css');
@import url('../common.css');
</style>
